<template>
  <form action="/search" method="get">
    <input
      id="site-search"
      aria-label="Search the site"
      class="absolute antialiased top-0 xl:min-h-btn-lg p-5 lg:px-8 xl:px-12 bg-black hover:bg-sky-blue focus:bg-sky-blue placeholder-light-grey text-light-grey transition-transform duration-300 motion-reduce:transition-none w-full"
      type="search"
      name="q"
      placeholder="Type to search…"
    />
  </form>
</template>
<script>
export default {};
</script>
