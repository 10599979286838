<template>
  <nav
    class="antialiased lg:hidden px-4 bg-dark-blue text-light-grey border-t border-white lg:border-0"
  >
    <ul class="flex flex-col list-none leading-tight mt-12">
      <mobile-nav-item
        v-for="item in items"
        :key="item.url"
        :item="item"
        class="border-b border-dark-grey"
      />
      <li class="border-b border-dark-grey lg:mt-auto">
        <a
          class="block h-full py-3"
          :aria-current="isCurrent({ url: '/contact' }) ? 'page' : ''"
          href="/contact"
          >Contact</a
        >
      </li>
      <li>
        <a
          class="block h-full py-3"
          :aria-current="isCurrent({ url: '/about-tsl' }) ? 'page' : ''"
          href="/about-tsl"
          >About</a
        >
      </li>
    </ul>
  </nav>
</template>
<script>
import MobileNavItem from "@/masthead/vue-components/MobileNavItem";

export default {
  components: {
    MobileNavItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  beforeDestroy() {
    document.body.style.overflow = "";
  },
  methods: {
    isCurrent(item) {
      return item.isCurrent || item.isCurrentAncestor || item.url === document.location.pathname;
    },
  },
};
</script>
