<template>
  <header
    :class="{ 'min-h-screen overflow-y-auto': navExpanded }"
    class="w-full bg-dark-blue relative"
  >
    <div class="masthead flex flex-col lg:no-flex">
      <div class="flex flex-wrap justify-between">
        <div class="xl:hidden w-24">
          <site-logo :site-name="store.siteName" />
        </div>
        <div class="flex items-center justify-end">
          <button
            :aria-label="`${navExpanded ? 'Hide' : 'Show'} primary navigation`"
            class="p-4"
            type="button"
            @click="toggleNav"
          >
            <icon :icon="navExpanded ? 'x' : 'nav'" class="w-5 h-5" />
          </button>
          <button
            :aria-label="`${searchExpanded ? 'Hide' : 'Show'} search`"
            class="p-4 z-30 bg-black"
            type="button"
            @click="toggleSearch"
          >
            <icon :icon="searchExpanded ? 'x' : 'search'" class="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
    <mobile-nav v-if="navExpanded" :items="store.navItems" />
    <search-input v-if="searchExpanded" />
  </header>
</template>
<script>
import store from "@/masthead/store";

import Icon from "@/masthead/vue-components/Icon";
import SiteLogo from "@/masthead/vue-components/SiteLogo";
import SearchInput from "@/masthead/vue-components/SearchInput";
import MobileNav from "@/masthead/vue-components/MobileNav";

export default {
  name: "MobileMasthead",
  components: {
    Icon,
    SiteLogo,
    SearchInput,
    MobileNav,
  },
  data: () => ({
    store,
    navExpanded: false,
    searchExpanded: false,
  }),
  methods: {
    toggleSearch() {
      this.navExpanded = false;
      this.searchExpanded = !this.searchExpanded;
    },
    toggleNav() {
      this.searchExpanded = false;
      this.navExpanded = !this.navExpanded;
    },
  },
};
</script>
