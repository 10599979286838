<template>
  <component :is="mastheadComponent" />
</template>
<script>
import breakpointState from "@/lib/helpers/breakpointHelper";

import store from "@/masthead/store";

import MobileMasthead from "@/masthead/vue-components/MobileMasthead";
import DesktopMasthead from "@/masthead/vue-components/DesktopMasthead";

export default {
  components: {
    MobileMasthead,
    DesktopMasthead,
  },
  data: () => ({
    breakpoints: breakpointState(),
    store,
  }),
  computed: {
    isMobile() {
      return !this.breakpoints.lg;
    },
    mastheadComponent() {
      return this.isMobile ? MobileMasthead : DesktopMasthead;
    },
  },
};
</script>
