<template>
  <header class="w-full" @mouseleave="setInitialSubnav">
    <div class="masthead">
      <div class="flex flex-wrap justify-between">
        <div class="flex items-stretch justify-end flex-grow lg:w-9/12">
          <div class="flex-grow">
            <nav class="lg:pl-4 xl:pl-8 xxl:pl-16 relative">
              <ul class="flex items-stretch leading-none min-h-btn xl:min-h-btn-lg -mx-2 xl:-mx-4">
                <li v-for="item in items" :key="item.url" class="mx-2 xl:mx-4">
                  <a
                    class="flex items-center border-b-5 h-full"
                    :class="[isCurrent(item) ? 'font-medium' : 'border-transparent']"
                    :aria-current="isCurrent(item) ? 'page' : ''"
                    :href="item.url"
                    @mouseover="setCurrentSubnav(item)"
                    @click.prevent="handleToplevelNavClick(item)"
                    v-html="item.title"
                  />
                  <desktop-subnav
                    v-if="currentSubnavItem && item === currentSubnavItem"
                    :items="currentSubnavItem.children"
                  />
                </li>
              </ul>
            </nav>
            <nav>
              <ul class="lg:absolute flex items-stretch leading-none min-h-btn xl:min-h-btn-lg right-0 top-0 -mx-4">
                <li class="mx-2 xl:mr-4">
                  <a
                    :aria-current="isCurrent({ url: '/contact' }) ? 'page' : ''"
                    class="flex items-center border-b-5 h-full border-transparent"
                    href="/contact"
                    >Contact</a
                  >
                </li>
                <li class="mx-2 xl:mx-4">
                  <a
                    :aria-current="isCurrent({ url: '/contact' }) ? 'page' : ''"
                    class="flex items-center border-b-5 h-full border-transparent"
                    href="/about-tsl"
                    >About</a
                  >
                </li>
                <li class="mx-2 xl:mx-4">
                  <button
                    class="z-30 relative text-light-grey bg-black hover:bg-sky-blue focus:bg-sky-blue flex items-center justify-center right-0 min-w-btn xl:min-w-btn-lg h-full"
                    aria-label="Search"
                    type="button"
                    @click="toggleSearch"
                  >
                    <icon :icon="isSearchShown ? 'x' : 'search'" />
                  </button>
                </li>
              </ul>
            <search-input v-if="isSearchShown" />
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import breakpointState from "@/lib/helpers/breakpointHelper";

import store from "@/masthead/store";
import DesktopSubnav from "@/masthead/vue-components/DesktopSubnav";
import Icon from "@/masthead/vue-components/Icon";
import SearchInput from "@/masthead/vue-components/SearchInput";

export default {
  name: "DesktopMasthead",
  components: {
    DesktopSubnav,
    Icon,
    SearchInput,
  },
  data: () => ({
    breakpoints: breakpointState(),
    store,
    currentSubnavItem: null,
    isSearchShown: false,
  }),
  computed: {
    items() {
      return this.store.navItems;
    },
  },
  mounted() {
    this.setInitialSubnav();
  },
  methods: {
    isCurrent(item) {
      return item.isCurrent || item.isCurrentAncestor || item.url === document.location.pathname;
    },
    setInitialSubnav() {
      this.setCurrentSubnav(this.items.find((i) => i.isCurrent || i.isCurrentAncestor));
    },
    setCurrentSubnav(item) {
      this.currentSubnavItem = item;
    },
    handleToplevelNavClick(item) {
      if (item === this.currentSubnavItem) {
        document.location = item.url;
      } else {
        this.currentSubnavItem = item;
      }
    },
    toggleSearch() {
      this.isSearchShown = !this.isSearchShown;
    },
  },
};
</script>
