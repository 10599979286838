import Vue from "vue";
import Slider from "./Slider";

export const slider = (configs) =>
  configs.map(({ selectors }) => {
    const el = document.querySelector(selectors.el);
    const slides = [...el.querySelectorAll(selectors.slide)].map((slideEl) => {
      // console.log(selectors);
      const noJs = slideEl.querySelector(selectors.noJs);

      if (noJs) {
        noJs.parentNode.removeChild(noJs);
      }

      return slideEl.innerHTML;
    });

    return new Vue({
      el,
      render(h) {
        return h(Slider, {
          props: {
            slides,
          },
        });
      },
    });
  });
