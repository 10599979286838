import { partial } from "@/lib/partial";

function setVisibililty(element, hideValue, value) {
  if (value !== hideValue) {
    element.setAttribute("hidden", true);
    element.setAttribute("required", false);
    // eslint-disable-next-line
    element.value = "";
  } else {
    element.removeAttribute("hidden");
    element.setAttribute("required", true);
  }
}

function bind(selectors, triggerValue) {
  const selectElement = document.querySelector(selectors.select);
  const customElement = document.querySelector(selectors.custom);

  // create handler
  const handler = partial(partial(setVisibililty, customElement), triggerValue);

  // bind event
  const listener = selectElement.addEventListener("change", (e) => handler(e.target.value));

  // set initial state;
  handler(selectElement.value);

  return {
    selectElement,
    customElement,
    selectors,
    triggerValue,
    handler,
    listener,
  };
}

export const customSelect = (configs) =>
  configs.map(({ selectors, triggerValue }) => bind(selectors, triggerValue));
