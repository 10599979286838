<template>
  <li class="flex flex-wrap justify-between">
    <a
      :aria-current="isCurrent ? 'page' : ''"
      :href="item.url"
      :class="{ 'font-medium': isCurrent, 'pl-6 text-grey': isNested }"
      class="block flex-grow items-stretch h-full py-3"
      v-html="item.title"
    />
    <button v-if="hasChildren" class="w-12" @click="toggleChildren()">
      <icon
        icon="chevron"
        class="inline w-3 h-3 text-inherit transform"
        :class="{ 'rotate-180': isExpanded }"
      />
    </button>
    <template v-if="hasChildren">
      <ul
        v-for="child in item.children"
        v-show="isExpanded"
        :key="child.url"
        class="w-full transition duration-200"
      >
        <mobile-nav-item :item="child" :is-nested="true" />
      </ul>
    </template>
  </li>
</template>
<script>
import Icon from "@/masthead/vue-components/Icon";

export default {
  name: "MobileNavItem",
  components: {
    Icon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    /**
     * Is this a nested item?
     */
    isNested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasToggled: false,
    };
  },
  computed: {
    isCurrent() {
      return (
        this.item.isCurrent ||
        this.item.isCurrentAncestor ||
        this.item.url === document.location.pathname
      );
    },
    hasChildren() {
      return !!this.item.children?.length;
    },
    isExpanded() {
      return this.item.isExpanded || (this.isCurrent && !this.hasToggled);
    },
  },
  methods: {
    toggleChildren() {
      this.hasToggled = true;
      this.item.isExpanded = !this.item.isExpanded;
    },
  },
};
</script>
