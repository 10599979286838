import Vue from "vue";
import Masthead from "./vue-components/Masthead";

import store from "./store";

export const masthead = (configs) =>
  configs.map(({ storeData, el }) => {
    Object.entries(storeData).forEach(([key, value]) => {
      if (Object.prototype.hasOwnProperty.call(store, key)) {
        store[key] = value;
      } else {
        throw new Error(`Can't set unknown store property ${key}`);
      }
    });
    return new Vue({
      el,
      render(h) {
        return h(Masthead);
      },
    });
  });
