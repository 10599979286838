<template>
  <vue-agile :dots="false">
    <div v-for="(slide, idx) in slides" :key="idx" v-html="slide" />

    <template slot="prevButton">
      <span class="btn btn--secondary btn--lg" title="Previous slide">
        <icon
          icon="arrow"
          class="flex-none inline-block ml-4 text-currentColor w-4 transform rotate-180"
        />
      </span>
    </template>
    <template slot="nextButton">
      <span class="btn btn--secondary btn--lg" title="Next slide">
        <icon icon="arrow" class="flex-none inline-block ml-4 text-currentColor w-4 transform" />
      </span>
    </template>
  </vue-agile>
</template>

<script>
// https://github.com/lukaszflorczak/vue-agile
import { VueAgile } from "vue-agile";
import Icon from "@/masthead/vue-components/Icon";

export default {
  components: {
    VueAgile,
    Icon,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style>
.agile__actions {
  display: block;
}
</style>
