<template>
  <ul
    ref="container"
    v-click-outside="clickOutsideHandler"
    class="antialiased subnav absolute left-0 right-0 mt-auto xl:ml-auto flex border-mid-grey border xl:pl-8 xxl:pl-12 whitespace-no-wrap z-30"
  >
    <li v-for="(item, idx) in items" ref="items" :key="item.url">
      <a
        :href="item.url"
        :class="[activeClass(item)]"
        :style="hiddenItemStyle(idx)"
        class="flex p-4 xl:py-6 text-dark-grey"
        v-html="item.title"
      />
    </li>
    <li v-if="hiddenItems.length" aria-hidden="true">
      <button
        aria-controls="more-items-container"
        :title="btnTitle"
        class="absolute right-0 p-4 xl:py-6"
        @click.prevent="toggleExpanded"
      >
        {{ hiddenItems.length }} More…
      </button>
      <div
        v-show="isExpanded"
        id="more-items-container"
        aria-hidden="true"
        class="subnav subnav-overflow absolute border flex left-0 right-0 w-auto border-mid-grey z-50"
      >
        <a
          v-for="item in hiddenItems"
          :key="item.url"
          :href="item.url"
          :class="[activeClass(item)]"
          class="flex p-4 xl:py-6"
          v-html="item.title"
        />
      </div>
    </li>
  </ul>
</template>
<script>
import throttle from "lodash.throttle";
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isExpanded: false,
      renderedWidth: 0,
      renderedItems: [],
      padAmount: 80,
      throttledResizeHandler: () => {},
    };
  },
  computed: {
    hiddenItems() {
      if (this.lastVisibleItemIndex > -1) {
        return this.items.slice(this.lastVisibleItemIndex + 1, this.items.length);
      }
      return [];
    },
    btnTitle() {
      return this.isExpanded ? "Hide additional items" : "Show more items";
    },
    availableWidth() {
      return this.renderedWidth - this.padAmount;
    },
    totalItemWidths() {
      return this.renderedItems.reduce((t, i) => t + i.width, 0);
    },
    lastVisibleItemIndex() {
      const lastItem = [...this.renderedItems].reverse().find((item) => {
        return item.runningTotalWidth < this.availableWidth;
      });

      if (typeof lastItem !== "undefined") {
        return this.renderedItems.findIndex((i) => i === lastItem);
      }
      return -1;
    },
  },
  mounted() {
    this.throttledResizeHandler = throttle(this.resizeHandler, 300);

    this.$nextTick(() => {
      this.bindResize();
    });
    this.resizeHandler();
  },
  beforeDestroy() {
    this.unbindResize();
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
    activeClass(item) {
      return item.isCurrent || item.isCurrentAncestor ? "text-sky-blue" : "";
    },
    clickOutsideHandler() {
      if (this.isExpanded) {
        this.toggleExpanded();
      }
    },
    hiddenItemStyle(itemIndex) {
      return {
        visibility: itemIndex > this.lastVisibleItemIndex ? "hidden" : "visible",
      };
    },
    resizeHandler() {
      if (this.$refs.container) {
        this.renderedWidth = this.$refs.container.offsetWidth;
        this.renderedItems = this.updateRenderedItemsWithWidths();
      }
    },
    bindResize() {
      window.addEventListener("resize", this.throttledResizeHandler);
    },
    unbindResize() {
      window.removeEventListener("resize", this.throttledResizeHandler);
    },
    updateRenderedItemsWithWidths() {
      if (this.$refs.items) {
        return this.$refs.items.reduce((acc, item, idx) => {
          const style = window.getComputedStyle(item);
          const width =
            item.offsetWidth + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
          const runningTotalWidth = idx > 0 ? acc[idx - 1].runningTotalWidth + width : width;

          return acc.concat([
            {
              item,
              width,
              runningTotalWidth,
            },
          ]);
        }, []);
      }
      return [];
    },
  },
  beforeDeactivate() {
    this.unbindResize();
  },
};
</script>
