<template>
  <div
    class="logo relative px-4"
    itemscope
    itemtype="http://schema.org/Organization"
    :class="modifierClass"
  >
    <a
      class="logo-link absolute top-0 right-0 bottom-0 left-0 a--plain"
      itemprop="url"
      href="/"
      :title="`Return to ${siteName} homepage`"
    >
      <span class="sr-only">{{ siteName }}</span>
    </a>
    <icon icon="tsl-logo" class="h-12 w-12" width="500" height="229" />
  </div>
</template>
<script>
import Icon from "@/masthead/vue-components/Icon";

export default {
  components: {
    Icon,
  },
  props: {
    siteName: {
      type: String,
      required: true,
    },
    modifier: {
      type: String,
      default: "",
    },
  },
  computed: {
    modifierClass() {
      return this.modifier ? `logo--${this.modifier}` : "";
    },
  },
};
</script>
